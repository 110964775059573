<template>
   <div>
      <div class="card-body">
         <p class="login-box-msg" style="text-align: justify; font-size: 12px">Após digitar seu email no campo abaixo e clicar no botão enviar, um link de redefinição de senha será enviado para o seu email.</p>

         <form>
            <div class="input-group mb-3">
               <input type="text" class="form-control" placeholder="Email" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-envelope"></span>
                  </div>
               </div>
            </div>
            <div class="row">
               <!-- /.col -->
               <div class="col-12">
                  <button type="submit" class="btn btn-outline-secondary btn-block"><b>Enviar</b></button>
               </div>
               <!-- /.col -->
            </div>
         </form>
      </div>
      <!-- /.card-body -->

   </div>
</template>

<script>
export default {
   name: "ForgotPassword"
}
</script>
